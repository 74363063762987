$(document).ready(function() {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".hamburger").hasClass("hamburger--scrolled")) {
        $(".hamburger").addClass("hamburger--scrolled");
        $(".name").addClass("name--scrolled");
      }
    } else {
      if ($(".hamburger").hasClass("hamburger--scrolled")) {
        $(".hamburger").removeClass("hamburger--scrolled");
        $(".name").removeClass("name--scrolled");
      }
    }
  });

  // menu
  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    if ($(this).hasClass("hamburger--clicked")) {
      $("body").css("overflow", "hidden");
      $(".name").addClass("name--hidden");
      $("#menu").addClass("menu--opened");
      $(".sidebar").addClass("sidebar--opened");
      $(".sidebar__phone").css("opacity", "0");
      setTimeout(function() {
        $(".menu__left, .menu__right").css("overflow", "auto");
      }, 1000);
      $("#search-query").on("click", function() {
        $(this)
          .siblings(".btn")
          .addClass("btn--active");
      });
      // if ($("#search-query").is(":focus")) {
      //   $(".menu__search .btn").addClass("btn--active");
      //   console.log("Form Focused")
      // } else {
      //   $(".menu__search .btn").removeClass("btn--active");
      // }
    } else {
      $("body").css("overflow", "auto");
      $(".name").removeClass("name--hidden");
      $("#menu").removeClass("menu--opened");
      $(".sidebar").removeClass("sidebar--opened");
      $(".nav-item").removeClass("black");
      $(".menu__left, .menu__right").css("overflow", "hidden");
      $(".menu__search .btn").removeClass("btn--active");
      $(".sidebar__phone").css("opacity", "1");
    }
  });

  $(".nav-item").on("mouseenter", event => {
    $(event.currentTarget)
      .removeClass("black")
      .siblings()
      .addClass("black");
  });

  $(".nav-item").on("mouseleave", event => {
    $(event.currentTarget)
      .siblings()
      .removeClass("black");
  });
});
