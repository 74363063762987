$(document).ready(() => {
    // check design build filter
    $("#designBuild").on("click", function() {
        if($("#designBuild").is(":checked"))
        {
            window.location.href = $("#designBuild").data("target")
        }
        else
        {
            window.location.href = $("#designBuild").data("target-off")   
        }    
    });
});
