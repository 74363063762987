$(document).ready(function() {
  // Provide a better date input UX
  $(".formpage__form #id_date, .formpage__form .dateinput").pickadate({
    format: "yyyy-mm-dd",
    formatSubmit: "yyyy-mm-dd",
    hiddenName: true
  });

  // Prevent double form submissions
  $("form").submit(() => {
    $(this)
      .find("button[type='submit']")
      .prop("disabled", true);
  });
});
