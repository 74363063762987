$(document).ready(function() {
  $("#homeSlider").slick({
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: $("#homeDots"),
    pauseOnHover: false,
    autoplay: true
  });

  setTimeout(function() {
    $(".slider__headline").addClass("slider__headline--visible");
  }, 500);
});
