$(document).ready(function() {
  $(".category").on("mouseenter", event => {
    $(event.currentTarget)
      .removeClass("category--collapsed")
      .siblings()
      .addClass("category--collapsed");
  });

  $(".category").on("mouseleave", event => {
    $(event.currentTarget)
      .siblings()
      .removeClass("category--collapsed");
  });
});
